<script setup>

import Logo from "@/assets/images/logo.png";
import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import {ref} from "vue";
const user = ref({
  email: '', name: ''
})
const router=useRouter()
const {layout}=defineProps(['layout'])
const loader=ref(false)

const getUser = async () => {

  try {
    loader.value=true
    const {data} = await axios.get('user')
    user.value = data
  } catch (e) {

  }finally {
    setTimeout(()=>{
      loader.value=false
    },1000)
  }
}

if (layout!=='main'){
  getUser()
}


const logout=async ()=>{

  try{
    const {data}=await axios.post('logout')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    await router.push('/login')
  }catch (e) {

  }

}
</script>

<template>
  <div class="w-[300px]">
    <div class="h-screen overflow-y-auto bg-gradient-to-l from-slate-800 to-slate-900">
      <div class="bg-slate-900  flex-col-center w-full h-36">
        <img class="invert" :src="Logo" alt="">
      </div>
      <div
          class="flex-center  text-slate-100 w-11/12 mx-auto p-3 bg-slate-800 flex justify-between rounded-lg
            -translate-y-5 border-slate">
        <div class=" border rounded-full p-2">
          <v-icon icon="fa fa-user" size="24" color="amber"/>
        </div>
        <p class="text-sm ">
          <v-progress-circular v-if="loader" indeterminate :size="30" :width="4"></v-progress-circular>
          <span v-else>{{user.name}}</span>
        </p>
        <v-btn @click="logout" variant="plain">
          <v-icon icon="fa fa-right-from-bracket"/>
        </v-btn>
      </div>
      <div class="mt-8 pr-3">
        <p class="text-gray-100 mb-4">فهرست</p>
        <div class="text-sm">
          <router-link :to="{name:'home'}" class="sidebar_link mb-8 ">
            <v-icon icon="fa fa-list bg-indigo-500/20 text-indigo-500 "/>
            <span>آگهی ها</span>
          </router-link>
          <router-link :to="{name:'specials'}" class="sidebar_link mb-8">
            <v-icon icon="fa fa-wand-magic-sparkles bg-orange-500/20 text-amber-500"/>
            <span >آگهی اختصاصی</span>
          </router-link>
          <router-link :to="{name:'categories'}" class="sidebar_link mb-8">
            <v-icon icon="fa fa-layer-group bg-pink-500/20 text-pink-500"/>
            <span >مدیریت دسته ها </span>
          </router-link>
          <router-link :to="{name:'users'}" class="sidebar_link mb-8">
            <v-icon icon="fa fa-users bg-slate-500/20 text-slate-400"/>
            <span>کاربران </span>
          </router-link>
          <router-link :to="{name:'banners'}" class="sidebar_link mb-8">
            <v-icon icon="fa fa-image bg-sky-500/20 text-sky-500"/>
            <span >مدیریت بنر</span>
          </router-link>
          <router-link :to="{name:'sitewide'}" class="sidebar_link mb-8">
            <v-icon icon="fa fa-link bg-stone-500/20 text-stone-500"/>
            <span >Site Wide </span>
          </router-link>
          <router-link :to="{name:'transactions'}" class="sidebar_link mb-8">
            <v-icon icon="fa fa-money-bills bg-purple-500/20 text-rose-500"/>
            <span>تراکنش ها</span>
          </router-link>
          <router-link :to="{name:'setting'}" class="sidebar_link mb-8">
            <v-icon icon="fa fa-gears bg-green-500/20 text-teal-500"/>
            <span>تنظمیات</span>
          </router-link>
          <router-link :to="{name:'profile'}" class="sidebar_link mb-8">
            <v-icon icon="fa fa-user-gear bg-slate-300/20 text-slate-300"/>
            <span>حساب کاربری</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>