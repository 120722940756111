<script setup>
import {inject, ref, watch} from "vue";
import axios from "axios";
import Advers from "@/components/Advers.vue";
import Header from "@/components/Header.vue";

// const selectedAdvertisement = ref({})
const emitter = inject('emitter')
const list = ref([])
const links = ref([])
const currentPage = ref(1)
// const dialogAccept = ref(false)
// const statusAdver = ref('accept')
const filter = ref('all')

// const changeAdverStatus = (status, item) => {
//   try {
//     dialogAccept.value = true
//     statusAdver.value = status
//     selectedAdvertisement.value = item
//   } catch (e) {
//
//   }
// }
const toggleLoader = (isVisible) => {
  emitter.emit('show', isVisible);
};

// fetch Data
const fetchAdvertisements = async function () {
  toggleLoader(true)
  const url = `/advertising?page=${currentPage.value}&f=${filter.value}`
  try {
    const {data: response} = await axios.get(url)
    list.value = response.data
    links.value = response.links
  } catch (e) {

  } finally {
    toggleLoader(false)
  }
}


watch([currentPage, filter], () => {
  fetchAdvertisements()
})

emitter.on("getData", async () => {
  await fetchAdvertisements()
})

fetchAdvertisements()
</script>

<template>
  <Header>
    <p>آگهی ها</p>
    <div class="flex-center gap-3">
      <p>فیلتر بر اساس</p>

      <v-btn-toggle base-color="#0f172a" divided v-model="filter"
                    color="amber-darken-4"
                    rounded="3"
                    border
                    mandatory
                    group>
        <v-btn size="small" value="all">
          همه
        </v-btn>

        <v-btn size="small" color="deep-purple-darken-1" value="vip">
          ویژه
        </v-btn>

        <v-btn size="small" color="blue-accent-3" value="0">
          ثبت
        </v-btn>
        <v-btn size="small" color="green-darken-1" value="1">
          تایید
        </v-btn>
        <v-btn size="small" color="red-accent-3" value="2">
          رد
        </v-btn>
        <v-btn size="small" color="red-accent-3" value="trash">
         حذف شده
        </v-btn>

      </v-btn-toggle>
    </div>
  </Header>

 <div class="p-2">
   <Advers :items="list" event="getData"/>
 </div>


  <!--  paginate-->
  <div class="flex-center mx-auto gap-4 w-full mt-12">
    <template v-for="link in links.slice(1,-1)">
      <button class="rounded-full border w-8 h-8" :class="link.active?'bg-slate-400 text-slate-100':'bg-slate-100'"
           @click="currentPage=link.label" >{{ link.label }}
      </button>
    </template>
  </div>
  <!--end paginate-->


  <!--  accept Dialog-->



</template>


