import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {useServices} from "@/boot/index.js";
import {install} from "@/service/axiosService.js";


const app = createApp(App)
useServices(app)
app.use(install)
app.use(router)
app.mount('#app')
