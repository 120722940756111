<script setup>
import {inject, ref} from "vue";
const show=ref(false)
const emitter = inject('emitter');
emitter.on('show',(value)=>{
  show.value=value;
})
</script>

<template>
<div class="wrapper z-[1000]" v-show="show">
  <div class="flex-col-center gap-4 w-64 h-48 bg-white rounded-md shadow-lg">
    <div class="loader"></div>
    <p>دریافت اطلاعات</p>
  </div>
</div>
</template>

<style scoped>
.wrapper{
  background: rgba(0,0,0,.5);
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>