export const install = (app) => {
    const emitter = app.config.globalProperties.emitter;

    app.axios.interceptors.request.use(config => config);

    app.axios.interceptors.response.use(
        response => response,
        error => handleErrorResponse(error, emitter)
    );
};

const handleErrorResponse = (error, emitter) => {
    if (error.response) {
        const { status, data } = error.response;

        if (status === 401) {
            handleUnauthorizedError(emitter);
        } else if (status === 500) {
            emitter.emit("showSnackError", 'خطای سرور');
        } else if (status === 422) {
            emitter.emit("showSnackError", data.message);
        }
    }


};

const handleUnauthorizedError = (emitter) => {
    emitter.emit("showSnackError", 'خطای دسترسی');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    // location.assign('/login');

};
