<script setup>

</script>

<template>
  <div
      class="flex items-center h-20 bg-gradient-to-l sticky left-0 top-0 z-[100] from-slate-900 to-slate-800
      text-slate-100 justify-between gap-6 p-3">
    <slot/>
  </div>
</template>

