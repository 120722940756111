<script setup>
import axios from "axios";
import moment from "jalali-moment";
import {inject, ref} from "vue";
const selectedAdvertisement = ref({})
const emitter = inject('emitter')
const dialogAccept = ref(false)
const statusAdver = ref('accept')
const showExpanded=ref(false)

const {items,event} = defineProps(['items','event'])

const changeAdverStatus = (status, item) => {
  try {
    dialogAccept.value = true
    statusAdver.value = status
    selectedAdvertisement.value = item
  } catch (e) {

  }
}

const changeStatus = async () => {
  try {
    const {data} = await axios.post(`/advertising/${selectedAdvertisement.value.id}/status`, {
      status: statusAdver.value === 'accept' ? 1 : 2,
    })
    emitter.emit(event)
    dialogAccept.value = false;
    selectedAdvertisement.value = {}

    const msg = statusAdver.value === 'accept' ? 'تایید' : 'رد';
    emitter.emit('showSnack', `${msg}  آگهی با موفقیت انجام شد `)
  } catch (e) {
    console.log(e)
  }
}

</script>

<template>
  <v-table    height="70vh" >
    <thead>
    <tr>
      <th></th>
      <th class="text-right">
        عنوان
      </th>
      <th>
        تاریخ ثبت
      </th>
      <th class="text-right">
        نوع
      </th>
      <th class="text-right">وضعیت</th>
      <th class="text-right">
        پرداخت
      </th>
      <th class="text-right">
        کاربر
      </th>

      <th class="text-right">
        دسته
      </th>
      <th class="text-right">
        عملیات
      </th>
    </tr>
    </thead>
    <tbody>
    <tr
        v-for="item in items"
        :key="item.id"
    >
      <td>
        <v-img class="w-12 hover:scale-[5] hover:-translate-x-[200%] duration-150"
               :src="item.image_url"/>
      </td>

      <td class="max-w-[180px] overflow-hidden">{{ item.title }}</td>
<!--      <td>{{item.created_at}}</td>-->
      <td>{{ moment(item?.created_at).locale('fa').format('YYYY/MM/DD') }}</td>
      <td>
      
        {{ item.vip ? 'ویژه' : 'رایگان' }}
      </td>
      <td :class="item.accept==='1'?'text-emerald-500':item.accept===0?'text-blue-600':'text-rose-500'">

        {{ item.accept_title }}
      </td>
      <td>

        <span v-if="item.pay==='1'">  <v-icon icon="fa fa-check fa-sm" color="green"/></span>
        <span v-else>  <v-icon icon="fa fa-xmark fa-sm" color="red"/></span>
      </td>
      <td>{{ item.user?.name }}</td>
      <td>{{ item.sub_category_title }}</td>
      <td>
        <p v-if="item.deleted_at" class="text-rose-500">حذف شده</p>
        <div v-else class="flex gap-2">
          <!--          <v-btn prepend-icon="fa fa-pen fa-sm" size="small" color="teal-darken-1">-->
          <!--            ویرایش-->
          <!--          </v-btn>-->


          <v-btn @click="changeAdverStatus('accept',item)" v-if="item.accept!=='1'" size="small"
                 color="#2563eb">
            تایید آگهی
          </v-btn>
          <v-btn @click="changeAdverStatus('reject',item);" v-if="item.accept!=='2'"
                 size="small" color="pink-accent-3">
            رد آگهی
          </v-btn>

        </div>
      </td>
    </tr>
    </tbody>


  </v-table>
  <div class="text-center pa-4">
    <v-dialog
        v-model="dialogAccept"
        max-width="500"
        persistent
    >


      <v-card
          variant="elevated"
          theme="dark"
          :color="statusAdver==='accept'?'gray':'blue-grey-darken-4'"
          :prepend-icon="statusAdver==='accept'?'fa fa-check':'fa fa-xmark'"
          :title="statusAdver==='accept'?'تایید آگهی':'رد آگهی'"

      >
        <v-img
            :src="selectedAdvertisement?.image_url"
            class="align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="300px"
            cover
        />

        <v-card-title> {{ selectedAdvertisement?.title }}</v-card-title>
        <v-card-text>
          دسته : {{ selectedAdvertisement.sub_category_title }}
        </v-card-text>
        <v-card-subtitle>
          <div class="flex justify-between">
            <p> کاربر : {{ selectedAdvertisement?.user?.name }}</p>
            <p> شرکت / موسسه : {{ selectedAdvertisement?.address?.company }}</p>
          </div>
        </v-card-subtitle>

        <v-card-actions>
          <v-card-subtitle>
            آگهی : {{ selectedAdvertisement.vip ? 'ویژه' : 'رایگان' }}
          </v-card-subtitle>

          <v-card-subtitle>
            پرداخت :
            <v-icon color="green" v-if="selectedAdvertisement.pay==='1'" icon="fa fa-check"/>
            <v-icon color="red" v-else icon="fa fa-xmark"/>

          </v-card-subtitle>
          <v-spacer></v-spacer>

        </v-card-actions>
        <template v-slot:actions>
          <v-btn variant="flat" color="#10b981" @click="changeStatus">
            {{ statusAdver === 'accept' ? 'تایید' : 'رد' }} آگهی
          </v-btn>

          <v-btn variant="outlined" color="red-accent-3" @click="dialogAccept = false">
            انصراف
          </v-btn>
          <v-btn
              color="orange" variant="outlined"
              :text="!showExpanded ? 'توضیحات' : 'بستن'"
              @click="showExpanded = !showExpanded"
          />
        </template>

          <v-expand-transition>
            <div v-show="showExpanded">
              <div class="p-2" v-html="selectedAdvertisement.description">
              </div>
            </div>
          </v-expand-transition>

      </v-card>
    </v-dialog>
  </div>
</template>

