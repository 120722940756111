import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const  Specials=()=> import("@/views/Specials.vue");
const  Users=()=> import("@/views/Users.vue");
const  Transactions=()=> import("@/views/Transactions.vue");
const  Banners=()=> import("@/views/Banners.vue");
const  Setting=()=> import("@/views/Setting.vue");
const SiteWide=()=> import("@/views/SiteWide.vue");
const Categories=()=> import("@/views/Categories.vue");
const Profile=()=> import("@/views/Profile.vue");
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/specials',
    name: 'specials',
    component: Specials
  },
  {
    path: '/categories',
    name: 'categories',
    component: Categories
  },{
    path: '/sitewide',
    name: 'sitewide',
    component: SiteWide
  },
  {
    path: '/users',
    name: 'users',
    component: Users
  },
     {
    path: '/transactions',
    name: 'transactions',
    component: Transactions
  },
     {
    path: '/banners',
    name: 'banners',
    component: Banners
  },
     {
    path: '/setting',
    name: 'setting',
    component: Setting
  },
     {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {title: 'ورود', layout: 'main'},
  }
]

const router = createRouter({
  history:createWebHistory(),
  routes
})
router.afterEach((to, from) => {
  if (to.path === '/' && from.path === '/login') {
    location.reload()
  }
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')

  if (to.path !== '/login' && !token) {
    next('/login')
  }
  if (to.path === '/login' && token) {
    next(from.path)
  }

  return next();

})

export default router
