import '@fortawesome/fontawesome-free/css/all.css' // Ensure your project is capable of handling css files
import axios from 'axios'
import VueAxios from 'vue-axios'
import mitt from 'mitt'
import 'vuetify/styles'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const emitter = mitt()
const vuetify = createVuetify({

    components,
    directives,
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        },
    },
})
export const useServices=(app)=>{
    const token = localStorage.getItem("token");
    // axios.defaults.baseURL = 'http://127.0.0.1:8000/api/v1/'
    axios.defaults.baseURL = 'https://oksanat.ir/api/v1/'
    if (token) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + token;
    }
    axios.defaults.headers.common["Accept"] = "application/json";
    app.config.globalProperties.emitter = emitter
    app.use(VueAxios,axios)
    app.use(vuetify,{

    });
    app.provide('axios', app.config.globalProperties.axios)
    app.config.productionTip = false
    // app.config.globalProperties.emitter=emitter
    app.provide('emitter', emitter)
}