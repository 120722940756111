<script setup>
import {inject, onUnmounted, ref} from "vue";
const showSnack=ref(false)
const message=ref('')
const color=ref('success')
const emitter = inject('emitter');
emitter.on('showSnack',(msg)=>{
  showSnack.value=true;
  message.value=msg
  color.value='success'
})
emitter.on('showSnackError',(msg)=>{
  showSnack.value=true;
  message.value=msg
  color.value='error'
})

</script>

<template>
  <v-snackbar :color="color" timeout="3000"  v-model="showSnack">
  {{message}}
  </v-snackbar>

</template>
