<script setup>
import {RouterLink, RouterView, useRoute, useRouter} from 'vue-router'
import {ref, watch} from "vue";
import Logo from './assets/images/logo.png'
import Loading from "@/components/Loading.vue";
import Sidebar from "@/components/Sidebar.vue";
import SnackBar from "@/components/SnackBar.vue";

const route = useRoute()
const router = useRouter()
const layout = ref(null)

watch(() => route.name, () => {
  if (route.meta !== undefined && route.meta.layout !== undefined) {
    layout.value = route.meta.layout
  }
})
const logout = () => {

  localStorage.removeItem('token')
  localStorage.removeItem('user')
  router.push('/login')
}
</script>

<template>
  <div class="flex h-screen">
   <template v-if="layout===null">
     <Sidebar :layout="layout"/>
   </template>
    <div class="w-full h-screen overflow-y-auto bg-white">

      <div class=" mx-auto overflow-y-auto">
        <Loading/>
        <snack-bar/>
        <main class="">
          <router-view/>
        </main>
      </div>
    </div>
  </div>
</template>

<style scoped>


</style>
